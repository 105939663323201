// @flow
import * as React from 'react'
import css from './style.scss'

type Props = {
	children: React.Node,
}

const Page = ({ children }: Props) => (
	<div className={css.wrapper}>{children}</div>
)

export default Page
