// @flow
import * as React from 'react'
import cx from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import Search from 'public/Search'
import DateTime from 'shared/ui/DateTime'
import useScrollPosition from 'shared/hooks/useScrollPosition'
import useWindowBounds from 'shared/hooks/useWindowBounds'
import Submenu from './Submenu'
import css from './style.scss'

const SCROLL_CHANGE = 115

export const HIDE_NAVIGATION = 990

type Props = {
	onSearchChange?: (newSearch: string) => void,
}

const Navbar = ({ onSearchChange }: Props) => {
	const [showSearch, setShowSearch] = React.useState<boolean>(false)
	const [showDropdown, setShowDropdown] = React.useState<boolean>(false)
	const windowBounds = useWindowBounds()
	const scrollPosition = useScrollPosition()
	const history = useHistory()

	const toggleShowDropdown = () => {
		setShowDropdown(!showDropdown)
	}

	const toggleShowSearch = () => {
		setShowSearch(!showSearch)
	}

	const handleSearch = (search: string) => {
		history.push({
			path: '/',
			search: '?search=' + search,
		})
	}

	return (
		<header className={css.header}>
			<div className={css.wrapper}>
				<div className={css.container}>
					<div className={css.navbar}>
						<ul className={cx(css.list, css.navList)}>
							<li>
								<DateTime
									format="ddd D MMM YY"
									date={new Date().toISOString()}
								/>
							</li>
							<li>
								<a className={css.navIcon}>
									<i className="fab fa-facebook-f" />
								</a>
							</li>
							<li className={css.hideForNow}>
								<a className={css.navIcon}>
									<i className="fab fa-twitter" />
								</a>
							</li>
							<li className={css.hideForNow}>
								<a className={css.navIcon}>
									<i className="fab fa-instagram" />
								</a>
							</li>
						</ul>
						<ul className={css.list}>
							<li className={css.seperator}>
								<Link className={css.navItem} to="/resend-my-tickets">
									{`Resend my tickets`}
								</Link>
							</li>
							<li className={css.seperator}>
								<Link className={css.navItem} to="/subscribe">
									{`Subscribe`}
								</Link>
							</li>
							<li>
								<i
									onClick={toggleShowSearch}
									className={cx('fas fa-search', css.search)}
								/>
								<Search
									onClick={handleSearch}
									buttonText="Go"
									classNames={{
										wrapper: cx(
											css.navSearch,
											showSearch ? '' : css.hideSearch,
										),
										button: css.button,
										input: css.inputMobileFriendly,
									}}
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div
				className={cx(css.navWrapper, {
					[css.scrollBackground]: scrollPosition > SCROLL_CHANGE,
				})}
			>
				<div className={css.container}>
					<nav className={css.nav}>
						<a className={css.logo}>
							{`book`}
							<i className={cx('fas fa-circle', css.dot)} />
							{`events`}
						</a>
						{windowBounds.width < HIDE_NAVIGATION && (
							<div
								className={cx(showDropdown ? css.close : css.bar)}
								onClick={toggleShowDropdown}
							/>
						)}
						<ul
							className={cx(css.list, css.subList, {
								[css.dropdown]: windowBounds.width <= HIDE_NAVIGATION,
								[css.hidden]:
									!showDropdown && windowBounds.width <= HIDE_NAVIGATION,
								[css.liBlock]: showDropdown,
							})}
						>
							<li>
								<Link className={css.subNavItem} to="/">
									<span>What&#39;s On?</span>
								</Link>
							</li>
							<li style={{ display: 'none' }}>
								<Submenu />
							</li>
							<li>
								<a href="/about" className={css.subNavItem}>
									{`About us`}
								</a>
							</li>
							<li>
								<a href="/locations" className={css.subNavItem}>
									{`Locations`}
								</a>
							</li>
							<li className={css.hideForNow}>
								<a className={css.subNavItem}>Artists</a>
							</li>
							<li className={css.hideForNow}>
								<a className={css.subNavItem}>Contact</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	)
}

export default Navbar
