// @flow
import * as React from 'react'
import cx from 'classnames'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import css from './styles.scss'

type Props = {
	buttonText: React.Node,
	onClick: (newSearch: string) => void | Promise<void>,
	classNames?: {
		wrapper?: string,
		input?: string,
		button?: string,
	},
}

const Search = ({ buttonText, onClick, classNames }: Props) => {
	const { search } = qs.parse(useLocation().search, {
		ignoreQueryPrefix: true,
	})
	const [text, setText] = React.useState<string>(
		typeof search === 'string' ? search : '',
	)

	const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
		setText(e.currentTarget.value)
	}

	const handleClick = () => {
		const requestText = text
		setText('')
		onClick(requestText)
	}

	const handleKeyboardEvent = (k: SyntheticKeyboardEvent<HTMLInputElement>) => {
		if (k.keyCode === 13) {
			const requestText = text
			setText('')
			onClick(requestText)
		}
	}

	React.useEffect(() => {
		setText(typeof search === 'string' ? search : '')
	}, [search])

	return (
		<div className={cx(classNames ? classNames.wrapper : '', css.wrapper)}>
			<input
				className={cx(classNames ? classNames.input : '', css.input)}
				type="text"
				placeholder="Search event, artist, venue..."
				value={text}
				onChange={handleInputChange}
				onKeyDown={handleKeyboardEvent}
			/>
			<button
				type="button"
				onClick={handleClick}
				className={cx(classNames ? classNames.button : '', css.button)}
			>
				{buttonText}
			</button>
		</div>
	)
}

export default Search
