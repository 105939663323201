// @flow
import * as React from 'react'
import Events from 'public/Events'
import { useParams } from 'react-router'
import api from 'shared/api'
import styles from '../Title/styles.scss'

type Performer = {
	id: number,
	slug: string,
	name: string,
}

const { useEffect, useState, useCallback } = React

const LandingPage = () => {
	const { slug } = useParams()

	const [performer, setPerformer] = useState<?Performer>()

	const fetchPerformer = useCallback(async () => {
		if (!slug) {
			return
		}

		const response = await api({
			path: `bandspage/${slug}`,
			method: 'GET',
			prefix: '/api/v1/',
		})

		if (response.ok) {
			const { name, id } = response.json

			setPerformer({
				id,
				slug,
				name,
			})
		} else {
			setPerformer({
				id: 0,
				name: 'Performer not found',
				slug,
			})
		}
	}, [slug])

	useEffect(() => {
		fetchPerformer()
	}, [fetchPerformer])

	if (!performer || !slug) {
		return (
			<section className={styles.title}>
				<h1>Loading...</h1>
			</section>
		)
	}

	return (
		<>
			<div className={styles.title}>
				<h1>
					{performer.name}
					{`'s Events`}
				</h1>
			</div>
			<Events performer={slug} />
		</>
	)
}

export default LandingPage
