// @flow
import React, { useState } from 'react'
import type { Event } from 'shared/entities/Event'
import api from 'shared/api'
import type { PaginatorTypeOverride } from 'shared/ui/Table'
import type { Errors } from 'shared/api'
import Paginated from 'shared/ui/List/Paginated'
import type { RouterProps } from 'admin-transfers/types'
import DateTime from 'shared/ui/DateTime'
import css from '../Title/styles.scss'

export default function (props: RouterProps) {
	const { match } = props
	const [events, setEvents] = useState<?PaginatorTypeOverride<Event>>(null)
	const [loading, setLoading] = useState<boolean>(true)
	const [errors, setErrors] = useState<?Errors>(null)

	const fetchEvents = React.useCallback(
		async (pageNumber: number = 1) => {
			const response = await api({
				path: `events/${String(match.params.eventId)}/recurring`,
				method: 'GET',
				prefix: '/api/v1/',
				query: { page: pageNumber },
			})

			setLoading(false)
			return response
		},
		[match.params.eventId],
	)

	const loadMoreEvents = async () => {
		if (!events) {
			return
		}

		const response = await fetchEvents(events.current_page + 1)

		if (response.ok) {
			setEvents((prevEvents) => {
				if (!prevEvents) {
					return response.json
				}

				const newEvents = Object.keys(response.json.data).map(
					(key) => response.json.data[key],
				)

				return {
					...response.json,
					data: prevEvents.data.concat(newEvents),
				}
			})
		} else {
			setErrors(response.json.errors)
		}
	}

	React.useEffect(() => {
		const handleFetchEvents = async () => {
			const response = await fetchEvents()

			if (response.ok) {
				setEvents({
					...response.json,
					data: Object.keys(response.json.data).map(
						(key) => response.json.data[key],
					),
				})
			} else {
				setErrors(response.json.errors)
			}
		}
		handleFetchEvents()
	}, [fetchEvents, match.params.organiserId, match.params.eventId])

	if (loading) {
		return <p>Loading...</p>
	}

	if (errors || !events) {
		return <p>There was an error loading the recurring events...</p>
	}

	return (
		<>
			<div className={css.titleContainer}>
				<h1>Recurring Events</h1>
			</div>
			<Paginated
				itemClassName={css.item}
				listClassName={css.list}
				items={events}
				paginatorType="APPENDS"
				handleNext={loadMoreEvents}
				wrapperClassName={css.paginator}
				buttonsClassName={css.paginatorButton}
				buttonsDisabledClassName={css.pagnatorButtonsDisable}
				pageCountClassName={css.paginatorPageCount}
			>
				{(event) => (
					<>
						{event.start_date ? (
							<aside className={css.sideDate}>
								<DateTime date={event.start_date} format="Do, MMM" />
							</aside>
						) : (
							<aside className={css.noSideDate} />
						)}
						<a className={css.gotoButton} href={event.endpoint}>
							<div className={css.imageHolder}>
								<img
									className={css.image}
									src={event.bg_image_path}
									alt="Event logo"
								/>
							</div>
							<aside className={css.description}>
								<aside>
									<h1 className={css.eventTitle}>{event.title}</h1>
									<aside className={css.subheading}>
										{event.start_date && (
											<p>
												{`Start: `}
												<DateTime
													date={event.start_date}
													format="ddd, MMM D, YYYY h:mm a"
												/>
											</p>
										)}
										{`Location: `}
										{event.home_location}
									</aside>
									<div className={css.fakeButton}>More Info</div>
								</aside>
							</aside>
						</a>
					</>
				)}
			</Paginated>
		</>
	)
}
