// @flow
import * as React from 'react'
import useWindowBounds from 'shared/hooks/useWindowBounds'
import cx from 'classnames'
import { HIDE_NAVIGATION } from '../index'
import css from './styles.scss'

const Submenu = () => {
	const [showSubmenu, setShowSubmenu] = React.useState<boolean>(false)
	const windowBounds = useWindowBounds()

	const toggleShowSubmenu = () => {
		setShowSubmenu(!showSubmenu)
	}

	const phoneView = windowBounds.width <= HIDE_NAVIGATION

	return (
		<div
			className={cx(css.submenuButton, css.subNavItem, {
				[css.inlineSubmenu]: phoneView,
			})}
			onMouseEnter={!phoneView ? toggleShowSubmenu : undefined}
			onMouseLeave={!phoneView ? toggleShowSubmenu : undefined}
			onClick={phoneView ? toggleShowSubmenu : undefined}
			onFocus={toggleShowSubmenu}
		>
			<a className={css.subNavItem}>
				{`All events`}
				<i
					className={cx('fas fa-chevron-down', css.dropdownIcon, {
						[css.hidden]: phoneView,
					})}
				/>
			</a>
			<ul
				className={cx(css.submenu, {
					[css.inlineSubmenuList]: phoneView,
					[css.hideDropdown]: !showSubmenu,
				})}
			>
				<li>
					<a className={css.navItem}>Music Events</a>
				</li>
				<li>
					<a className={css.navItem}>Festivals</a>
				</li>
				<li>
					<a className={css.navItem}>Food & Drink</a>
				</li>
				<li>
					<a className={css.navItem}>Paranormal Events</a>
				</li>
				<li>
					<a className={css.navItem}>Christmas Events</a>
				</li>
			</ul>
		</div>
	)
}

export default Submenu
