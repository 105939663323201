// @flow
import qs from 'qs'

export type FormErrors = {|
	[field: string]: string[],
|}

export type Errors = {|
	message: string,
	errors?: FormErrors,
	exception?: string,
|}

export class ApiResponse {
	response: Response

	json: any

	constructor(response: Response, json: any) {
		this.response = response
		this.json = json
	}

	get data(): any {
		return this.json.data
	}

	get meta(): any {
		return this.json.meta
	}

	get ok(): boolean {
		return this.response.ok
	}

	get status(): number {
		return this.response.status
	}

	get errors(): ?FormErrors {
		if (this.status === 422) {
			return this.json.errors || {}
		}
		return this.response.ok ? null : this.json
	}
}

export type Query = {
	[key: string]: string | string[] | number | number[] | Query | void | boolean,
}

export type Props = {
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
	headers?: { [key: string]: string },
	path: string,
	body?: Object | FormData | Blob | File | string,
	query?: Query,
	prefix?: string,
}

const token: ?HTMLElement = document.querySelector('meta[name="csrf-token"]')

const api = async ({
	method = 'GET',
	path,
	headers,
	body,
	query,
	prefix = '/my-api/',
}: Props): Promise<ApiResponse> => {
	let generatedPath = ''
	generatedPath = path.startsWith('http')
		? path
		: `${prefix}${path.replace(/^\//, '')}`

	if (query && Object.keys(query || {}).length) {
		generatedPath = `${generatedPath}?${qs.stringify(query)}`
	}

	const requestHeaders = headers || {}

	if (token instanceof HTMLMetaElement) {
		requestHeaders['x-csrf-token'] = token.content
	}

	const header = new Headers({
		...requestHeaders,
		accept: 'application/json',
	})

	if (!(body instanceof FormData)) {
		header.append('content-type', 'application/json')
	}

	const response = await fetch(generatedPath, {
		method,
		credentials: 'same-origin',
		headers: header,
		body:
			header.get('content-type') === 'application/json'
				? JSON.stringify(body)
				: body,
	})

	if (response.status === 401) {
		window.location = '/login'
	}

	let json = {}

	try {
		json = await response.clone().json()
	} catch (e) {
		return new ApiResponse(response, {})
	}

	return new ApiResponse(response, json)
}

export default api
