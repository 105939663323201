// @flow
import * as React from 'react'
import Page from 'public/ui/Page'
import api from 'shared/api'
import Paginated from 'shared/ui/List/Paginated'
import type { PaginatorTypeOverride } from 'shared/ui/Table'
import type { Event as TEvent } from 'shared/entities/Event'
import type { Errors } from 'shared/api'
import css from '../style.scss'
import Search from './Search'

const { useState, useEffect, useCallback } = React

const Locations = () => {
	const [slug, setSlug] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(true)
	const [locations, setLocations] =
		useState<?PaginatorTypeOverride<TEvent>>(null)
	const [errors, setErrors] = useState<?Errors>(null)

	const onSearch = async (value: string): Promise<void> => {
		setSlug(value)
	}

	const fetchLocations = useCallback(
		async (page: number = 1) => {
			const response = await api({
				path: '/locations',
				method: 'GET',
				prefix: '/api/v1/',
				query: {
					slug,
					page,
					includeEmpty: false,
				},
			})

			setLoading(false)

			return response
		},
		[setLoading, slug],
	)

	useEffect(() => {
		const handleFetch = async () => {
			const response = await fetchLocations()

			if (response.ok) {
				setLocations({
					...response.json,
					data: Object.keys(response.json.data).map(
						(key) => response.json.data[key],
					),
				})
			} else {
				setErrors(response.json)
			}
		}

		handleFetch()
	}, [fetchLocations, slug])

	if (loading) {
		return (
			<Page>
				<div className={css.wrapper}>
					<h1 className={css.header}>Loading...</h1>
				</div>
			</Page>
		)
	}

	if (errors) {
		return (
			<Page>
				<div className={css.wrapper}>
					<h1 className={css.header}>
						{`Something went wrong while fetching events`}
					</h1>
				</div>
			</Page>
		)
	}

	if (!locations || locations.data.length === 0) {
		return (
			<Page>
				<div className={css.wrapper}>
					<h1 className={css.header}>No locations found</h1>
				</div>
			</Page>
		)
	}

	const loadMoreLocations = async () => {
		const response = await fetchLocations(locations.current_page + 1)

		if (response.ok) {
			setLocations((prevLocations) => {
				if (!prevLocations) {
					return response.json
				}

				const newLocations = Object.keys(response.json.data).map(
					(key) => response.json.data[key],
				)

				return {
					...response.json,
					data: prevLocations.data.concat(newLocations),
				}
			})
		} else {
			setErrors(response.json.errors)
		}
	}

	return (
		<Page>
			<div className={css.wrapper}>
				<h1 className={css.header}>Locations</h1>
				<Search onSubmit={onSearch} />
				<Paginated
					itemClassName={css.item}
					listClassName={css.list}
					items={locations}
					paginatorType="APPENDS"
					handleNext={loadMoreLocations}
					wrapperClassName={css.paginator}
					buttonsClassName={css.paginatorButton}
					buttonsDisabledClassName={css.paginatorButtonsDisable}
					pageCountClassName={css.paginatorPageCount}
				>
					{(location) => (
						<>
							<a
								className={css.gotoButton}
								href={`/locations/${location.slug}/events`}
							>
								<aside className={css.description}>
									<aside>
										<h1 className={css.title}>{location.venue_name_full}</h1>
										<div className={css.subheading}>
											{`Address: `}
											{location.address || location.address_line_1}
										</div>
										<div className={css.fakeButton}>View Location</div>
									</aside>
								</aside>
							</a>
						</>
					)}
				</Paginated>
			</div>
		</Page>
	)
}

export default Locations
