// @flow
import * as React from 'react'
import EmailRequests from '../EmailRequests'

const Subscribe = () => {
	const handleSubmit = async (email: string) => {
		const response = await fetch(
			`https://www.ymlp.com/api/Contacts.Add?key=CFY8UR81ANH4X547GQY8&output=json&username=vinylrecordsuk&groupId=2&email=${email}`,
			{
				method: 'POST',
				mode: 'no-cors',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/jsonp',
				},
			},
		)
		console.log(response)
	}
	return (
		<EmailRequests
			title="Subscribe to mailing list"
			buttonText="Subscribe"
			onSubmit={handleSubmit}
			errors={null}
		/>
	)
}

export default Subscribe
