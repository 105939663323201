// @flow
import * as React from 'react'
import css from '../style.scss'

type Props = {
	onSubmit: (value: string) => Promise<void>,
}

const { useState, useCallback } = React

const Search = ({ onSubmit }: Props) => {
	const [value, setValue] = useState<string>('')

	const onChange = useCallback(
		(e): void => {
			setValue(e.target.value)
		},
		[setValue],
	)

	const handleClick = useCallback((): void => {
		onSubmit(value)
	}, [onSubmit, value])

	return (
		<div className={css.searchWrapper}>
			<input
				className={css.input}
				type="text"
				placeholder="Search by performer name, description or slug"
				value={value}
				onChange={onChange}
			/>
			<button className={css.button} type="button" onClick={handleClick}>
				{`Search`}
			</button>
		</div>
	)
}

export default Search
