// @flow
import * as React from 'react'
import Page from 'public/ui/Page'
import css from './style.scss'

const About = () => {
	return (
		<Page>
			<div className={css.wrapper}>
				<h1>About Book.Events</h1>
				<p>Book Events South Limited</p>
				<p>Building 1000, Lakeside North Harbour</p>
				<p>Western Road</p>
				<p>Portsmouth</p>
				<p>PO6 3EN</p>
				<p>
					{`You can contact book.events at`}
					<a href="mailto:info@book.events">info@book.events</a>
				</p>
			</div>
		</Page>
	)
}

export default About
