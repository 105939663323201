// @flow
import * as React from 'react'

const useWindowBounds = (debounce: number = 500) => {
	const [height, setHeight] = React.useState<number>(0)
	const [width, setWidth] = React.useState<number>(0)

	const ref = React.useRef<{ id: ?IntervalID, ele: HTMLElement }>({})

	React.useEffect(() => {
		const refCopy = ref
		const updateWindowSize = () => {
			setWidth(window.innerWidth)
			setHeight(window.innerHeight)
		}

		updateWindowSize()

		const debounceResize = () => {
			refCopy.current.id = setInterval(updateWindowSize, debounce)
		}

		refCopy.current.ele = window

		refCopy.current.ele.addEventListener('resize', debounceResize)
		return () => {
			window.removeEventListener('resize', debounceResize)
			if (refCopy.current.id) {
				clearInterval(refCopy.current.id)
			}
		}
	}, [])

	return {
		height,
		width,
	}
}

export default useWindowBounds
