// @flow
import * as React from 'react'
import Page from 'public/ui/Page'
import type { Errors, FormErrors } from 'shared/api'
import cx from 'classnames'
import css from './style.scss'

type Props = {
	title: string,
	onSubmit: (email: string) => Promise<void>,
	errors: ?Errors,
	buttonText: string,
	emailSent?: boolean,
	error?: ?string,
}

const EmailRequests = ({
	title,
	onSubmit,
	errors,
	buttonText,
	emailSent,
	error,
}: Props) => {
	const [email, setEmail] = React.useState<string>('')

	const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
		setEmail(e.currentTarget.value)
	}

	const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault()
		onSubmit(email)
	}

	if (emailSent) {
		return (
			<Page>
				<section className={css.wrapper}>
					<h2 className={css.title}>{title}</h2>
					<p>An email has been sent with existing orders</p>
				</section>
			</Page>
		)
	}

	const displayErrors = (formErrors: FormErrors) => {
		return Object.keys(formErrors).map((key: string) => {
			return (
				<p key={key} className={css.error}>
					{formErrors[key]}
				</p>
			)
		})
	}

	return (
		<Page>
			<section className={css.wrapper}>
				<h2 className={css.title}>{title}</h2>
				<form onSubmit={handleSubmit}>
					<label htmlFor="email" className={css.labelWrapper}>
						<p className={css.label}>Email Address</p>
						<input
							className={cx(errors ? css.errors : '', css.input)}
							type="text"
							name="email"
							value={email}
							placeholder="Email"
							onChange={handleChange}
						/>
						{errors && errors.errors && (
							<div>{displayErrors(errors.errors)}</div>
						)}
						{error && <p className={css.error}>{error}</p>}
					</label>
					<div>
						<button className={css.button} type="submit">
							{buttonText}
						</button>
					</div>
				</form>
			</section>
		</Page>
	)
}

export default EmailRequests
