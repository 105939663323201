// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-Search-styles__wrapper--NalSG{display:flex}.public-Search-styles__input--o5PaX{padding:11.2px;border:none;font-size:12px;font-weight:400;width:inherit}.public-Search-styles__input--o5PaX::placeholder{color:#fa7f1c}.public-Search-styles__button--u6Cks{padding:.5rem 1.4rem;background-color:inherit;display:inline-block;border:none;min-width:30px;min-height:100%}`, "",{"version":3,"sources":["webpack://./resources/react/public/Search/styles.scss","webpack://./resources/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,sCACC,YAAA,CAGD,oCACC,cAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CACA,iDACC,aCZO,CDgBT,qCACC,oBAAA,CACA,wBAAA,CACA,oBAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `public-Search-styles__wrapper--NalSG`,
	"input": `public-Search-styles__input--o5PaX`,
	"button": `public-Search-styles__button--u6Cks`
};
export default ___CSS_LOADER_EXPORT___;
