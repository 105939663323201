// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-pages-EmailRequests-style__wrapper--aRTBt{background-color:#fff;color:#000;width:1140px;margin:0 auto;padding:5px 15px 15px 15px;margin-bottom:50vh}@media(max-width: 1200px){.public-pages-EmailRequests-style__wrapper--aRTBt{width:940px}}@media(max-width: 990px){.public-pages-EmailRequests-style__wrapper--aRTBt{width:720px}}@media(max-width: 768px){.public-pages-EmailRequests-style__wrapper--aRTBt{width:90%}}.public-pages-EmailRequests-style__title--pQrJj{text-align:center}.public-pages-EmailRequests-style__errors--kEVW3{border-color:red}.public-pages-EmailRequests-style__label--dlpWj{font-size:20pt}.public-pages-EmailRequests-style__labelWrapper--lpqXU{width:100%}.public-pages-EmailRequests-style__input--FoPXu{font-size:16px;padding:10px 5px;border:1px solid #e0e0e0;width:inherit}.public-pages-EmailRequests-style__button--uh2RN{margin:0 auto;border:none;background:#fa7f1c;color:#fff;padding:10px 16px;text-decoration:to-upper-case;display:flex}`, "",{"version":3,"sources":["webpack://./resources/react/public/pages/EmailRequests/style.scss","webpack://./resources/assets/scss/_variables.scss","webpack://./resources/assets/scss/public.scss"],"names":[],"mappings":"AAEA,kDACC,qBCDO,CDEP,UCDO,CDEP,YAAA,CACA,aAAA,CACA,0BAAA,CACA,kBAAA,CEMA,0BFZD,kDASE,WAAA,CAAA,CESD,yBFlBD,kDAaE,WAAA,CAAA,CEWD,yBFxBD,kDAiBE,SAAA,CAAA,CAIF,gDACC,iBAAA,CAGD,iDACC,gBAAA,CAGD,gDACC,cAAA,CAGD,uDACC,UAAA,CAGD,gDACC,cAAA,CACA,gBAAA,CACA,wBAAA,CACA,aAAA,CAGD,iDACC,aAAA,CACA,WAAA,CACA,kBChDQ,CDiDR,UChDO,CDiDP,iBAAA,CACA,6BAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `public-pages-EmailRequests-style__wrapper--aRTBt`,
	"title": `public-pages-EmailRequests-style__title--pQrJj`,
	"errors": `public-pages-EmailRequests-style__errors--kEVW3`,
	"label": `public-pages-EmailRequests-style__label--dlpWj`,
	"labelWrapper": `public-pages-EmailRequests-style__labelWrapper--lpqXU`,
	"input": `public-pages-EmailRequests-style__input--FoPXu`,
	"button": `public-pages-EmailRequests-style__button--uh2RN`
};
export default ___CSS_LOADER_EXPORT___;
