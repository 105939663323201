// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-pages-About-style__wrapper--LPq_O{background:#fff;color:#000;width:1140px;padding:10px 15px 15px 15px;margin:0 auto}`, "",{"version":3,"sources":["webpack://./resources/react/public/pages/About/style.scss","webpack://./resources/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,0CACC,eCDO,CDEP,UCDO,CDEP,YAAA,CACA,2BAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `public-pages-About-style__wrapper--LPq_O`
};
export default ___CSS_LOADER_EXPORT___;
