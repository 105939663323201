// @flow
import * as React from 'react'
import cx from 'classnames'
import Search from 'public/Search'
import css from './styles.scss'

type Props = {
	onSearchChange: (newSearch: string) => void | Promise<void>,
}
const Title = ({ onSearchChange }: Props) => {
	return (
		<section className={css.title}>
			<Search
				onClick={onSearchChange}
				classNames={{
					button: css.button,
					wrapper: css.searchWrapper,
					input: css.searchInput,
				}}
				buttonText={
					<React.Fragment>
						<i className={cx('fas fa-circle', css.dot)} />
						{`go`}
					</React.Fragment>
				}
			/>
		</section>
	)
}

export default Title
