// @flow
import * as React from 'react'
import moment from 'moment'

type Props = {
	date: string,
	format?: string,
}

const DateTime = ({ date, format }: Props) => {
	return (
		<React.Fragment>
			{moment(date, moment.ISO_8601, true).format(format || 'YYYY-MM-DD')}
		</React.Fragment>
	)
}

export default DateTime
