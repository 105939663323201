// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-ui-Page-style__wrapper--tBgbq{padding-top:15rem}`, "",{"version":3,"sources":["webpack://./resources/react/public/ui/Page/style.scss"],"names":[],"mappings":"AAEA,sCACC,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `public-ui-Page-style__wrapper--tBgbq`
};
export default ___CSS_LOADER_EXPORT___;
