// @flow
import * as React from 'react'

const useScrollPosition = () => {
	const [scrollPosition, setScrollPosition] = React.useState<number>(0)

	React.useEffect(() => {
		const updateScrollPosition = () => {
			setScrollPosition(window.scrollY)
		}

		updateScrollPosition()

		document.addEventListener('scroll', updateScrollPosition)
		return () => document.removeEventListener('scroll', updateScrollPosition)
	})

	return scrollPosition
}

export default useScrollPosition
