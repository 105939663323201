// @flow
import * as React from 'react'
import Title from 'public/Title'
import Events from 'public/Events'
import { useHistory } from 'react-router-dom'

const LandingPage = () => {
	const history = useHistory()

	const handleSearchChange = (search: string) => {
		history.push({
			path: '/',
			search: '?search=' + search,
		})
	}

	return (
		<>
			<Title onSearchChange={handleSearchChange} />
			<Events />
		</>
	)
}

export default LandingPage
