// @flow
import * as React from 'react'
import api from 'shared/api'
import type { Errors } from 'shared/api'
import EmailRequests from '../EmailRequests'

const ResendMyTickets = () => {
	const [errors, setErrors] = React.useState<?Errors>(null)
	const [error, setError] = React.useState<?string>(null)
	const [emailSent, setEmailSent] = React.useState<boolean>(false)
	const handleSubmit = async (email: string) => {
		const headers = {}

		const meta = document.querySelector('meta[name="csrf-token"]')

		if (meta) {
			const content = meta.getAttribute('content')

			if (typeof content === 'string') {
				headers['csrf-token'] = content
			}
		}

		const response = await api({
			prefix: '',
			method: 'POST',
			path: 'resend-my-tickets',
			body: { email },
			headers,
		})
		if (response.ok) {
			setEmailSent(true)
		} else if (response.status === 422) {
			setErrors(response.json)
		} else {
			setError(response.json.error)
		}
	}
	return (
		<EmailRequests
			title="Resend My Tickets"
			onSubmit={handleSubmit}
			errors={errors}
			buttonText="Get my orders"
			emailSent={emailSent}
			error={error}
		/>
	)
}

export default ResendMyTickets
