// @flow
import * as React from 'react'

export type Props<I> = {|
	items: Array<I>,
	children: (item: I) => React.Node,
	listClassName?: string,
	itemClassName?: string,
|}

export type ListItem = { +id: string | number }

const List = <I: ListItem>({
	children,
	itemClassName,
	items,
	listClassName,
}: Props<I>) => {
	const renderItem: (I) => React.Node = (item: I) => {
		return (
			<li className={itemClassName} key={item.id}>
				{children(item)}
			</li>
		)
	}
	return <ul className={listClassName}>{items.map(renderItem)}</ul>
}

export default List
